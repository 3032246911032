var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _vm.isLoading
        ? _c(
            "v-container",
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type:
                    "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
                }
              })
            ],
            1
          )
        : _vm.document
        ? _c("document-detail-card", {
            staticStyle: {
              height: "calc(100vh - 180px)"
            },
            attrs: {
              document: _vm.document,
              signature: _vm.signature
            }
          })
        : _c("latest-entries-card-empty", {
            staticClass: "mt-16"
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }